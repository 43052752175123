body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Public Sans";
}

.formControlBox fieldset,
.userBox fieldset {
  border: none;
}

.formControlBox label {
  background: white;
  padding: 0 8px;
}

.inputBox input {
  padding: 6px;
  background: white;
  font-size: 16px;
}

@keyframes slideAnim {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
}

.looper__innerList[data-animate="true"] > .looper__listInstance {
  animation: slideAnim linear infinite;
}
